
import { AudioPlayback } from '@/services/audio/audioPlayback';
import { AudioRecorder } from '@/services/audio/audioRecorder';
import Icon from '@/components/content/Icon.vue';
import { computed, defineComponent, ref, shallowRef } from 'vue';
import AudioPlayer from './molecules/AudioPlayer.vue';

export default defineComponent({
  name: 'QaRecord',
  components: {
    Icon,
    AudioPlayer,
  },
  setup() {
    const isPlayingBack = ref<boolean>(false);

    const audioRecorder = shallowRef<AudioRecorder | undefined>(undefined);
    const audioPlayback = shallowRef<AudioPlayback | undefined>(undefined);

    const isPaused = computed(() => {
      return !!audioPlayback.value && !audioPlayback.value.playing.value;
    });

    const isRecording = computed(() => {
      return !!audioRecorder.value && audioRecorder.value.isRecording.value;
    });

    const record = async () => {
      try {
        if (!audioRecorder.value) {
          audioRecorder.value = new AudioRecorder();
        }
        await audioRecorder.value.start();
      } catch (e) {
        console.log(e);
      }
    };

    const stopRecording = async () => {
      const result = await audioRecorder.value!.stop();
      audioPlayback.value = result.audio;
      isPlayingBack.value = true;
      audioRecorder.value = undefined;
    };

    const play = () => {
      audioPlayback.value!.play();
    };

    const pause = () => {
      audioPlayback.value!.pause();
    };

    const stop = () => {
      isPlayingBack.value = false;
      audioPlayback.value!.stop();
    };

    return {
      isRecording,
      isPlayingBack,
      isPaused,
      audioPlayback,
      record,
      stopRecording,
      play,
      pause,
      stop,
    };
  },
});
